.loader {
  border: 5px solid var(--kt-body-bg);
  ;
  border-top: 5px solid var(--kt-primary);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin-bottom: 2%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  padding: 0;
}

.splash-screen {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Helvetica, 'sans-serif';
  background-color: var(--kt-body-bg);
  color: #5E6278;
  line-height: 1;
  font-size: 14px;
  font-weight: 400;
}

.splash-screen span {
  color: #5E6278;
  transition: none !important;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

[data-theme="dark"] .splash-screen {
  background-color: #151521;
  color: #92929F;
}

[data-theme="dark"] .splash-screen span {
  color: #92929F;
}


#root {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}