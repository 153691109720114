//
// Footer
//
.footer {
	background-color: var(--kt-footer-bg-color);
}

// Desktop mode
@include media-breakpoint-up(lg) {
	.footer {
		// Aside enabled mode
		.aside-enabled & {
			padding-left: get($content-spacing, desktop);
		}
	}
}