@use '@angular/material' as mat;

html,
body {
  height: 100%;
  margin: 0;
}

/*** ngx loading bar ***/
.ngx-spinner {
  color: $primary !important;
}

.ngx-bar {
  background-color: $primary !important;
}

/** IMAGES **/
.seo-image {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  left: -9999px;
  /* Push the image out of the viewport */
  pointer-events: none;
}


/* Form required */
.required:after {
  content: " *";
  color: red;
}

/* Custom Style */
.disabled-link {
  pointer-events: none;
}

.cursor-grab {
  cursor: grab !important;
}

.responsive-scroll {
  height: calc(100vh - 100px) !important;
  overflow: auto;
}

.h-14px {
  height: 14px !important;
}

.card-toolbar.flex-grow-2 {
  flex-grow: 2;
}

/* Animation */
.shake-animation {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-10px);
  }

  50% {
    transform: translateX(10px);
  }

  75% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0);
  }
}

/* Mobile */
@media (max-width: 767px) {
  .responsive-scroll {
    height: calc(100vh - 150px) !important;
  }
}

/* Extra Small Devices (phones, less than 576px) */
@media (max-width: 374.98px) {
  .search-input-plp.form-control {
    width: 132px !important;
  }
}

a.link-underline-hover:hover {
  text-decoration: underline !important;
  text-underline-offset: 0.125em !important;
}

.link-underline {
  text-decoration: underline !important;
  text-underline-offset: 0.125em !important;
  cursor: pointer;
}

a.link-visited:visited {
  color: purple !important;
  text-decoration-color: purple !important;
}

.select-spinner {
  position: absolute !important;
  right: 0;
}

/* Rating stars */
.stars {
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  font-size: 15px;
  font-family: sans-serif;
  line-height: 1;
}

.stars::before {
  content: "★★★★★";
  letter-spacing: 0px;
  background: linear-gradient(90deg,
      #fc0 var(--percent),
      #eaeaea var(--percent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/************************
Angular Material 
************************/
:root {
  @include mat.paginator-overrides((
    container-text-color: inherit,
    container-text-size: 11px,
    container-background-color: inherit,
  ));
  @include mat.tooltip-overrides((
    supporting-text-line-height: inherit
  ));
  @include mat.radio-overrides((
    label-text-size: 12px
  ));
  @include mat.button-overrides((
    filled-container-height: 35px,
    outlined-container-height: 35px,
    text-container-height: 35px
  ));

  //--mdc-filled-button-container-height
}

.mat-form-field-fluid {
  display: block !important;
}

.cdk-overlay-container,
.cdk-overlay-pane {
  z-index: 9999 !important;
}

html[data-theme="light"] {

  .success-dialog {
    --mdc-text-button-label-text-color: var(--kt-white) !important;
    --mdc-snackbar-container-color: var(--kt-primary) !important;
  }

  .danger-dialog {
    --mdc-text-button-label-text-color: var(--kt-white) !important;
    --mdc-snackbar-container-color: var(--kt-danger) !important;
  }
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 0.5em !important;
  font-size: 90%;
}

.mat-sort-header-content {
  text-decoration: underline !important;
  text-underline-offset: 0.25em !important
}