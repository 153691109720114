//
// Root
//

// Light mode
@include theme-light() {
    // Page bg
    --kt-page-bg: #{$page-bg};

    // Header
    --kt-header-bg-color: #{get($header-config, bg-color)};
    --kt-header-border-color: #{get($header-config, border-color)};

    // Footer
    --kt-footer-bg-color: #{$footer-bg-light};
}

// Dark mode
@include theme-dark() {
    // Page bg
    --kt-page-bg: #{$page-bg-dark};

    // Header
    --kt-header-bg-color: #{get($header-config, bg-color-dark)};
    --kt-header-border-color: #{get($header-config, border-color-dark)};

    // Footer
    --kt-footer-bg-color: #{$footer-bg-dark};
}