@use '@angular/material' as mat;
@use "theme-colors" as theme; // Using custom theme palette and injecting via angular.json

@include mat.core(); // Include mat.core() here

/***** Light theme *****/
$too-perfect-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: theme.$primary-palette
  ),
  typography: (
    brand-family: $font-family-sans-serif,
    plain-family: $font-family-sans-serif
  ),
  density: (
    scale: -3
  )
));

/***** Dark theme *****/
$too-perfect-theme-dark: mat.define-theme((
  color: (
    theme-type: dark,
    primary: theme.$tertiary-palette
  ),
  typography: (
    brand-family: $font-family-sans-serif,
    plain-family: $font-family-sans-serif,
  ),
  density: (
    scale: -3
  )
));


// Include all component themes based on the theme variable
html {
  @include mat.elevation-classes();
  @include mat.app-background();
  @include mat.all-component-themes($too-perfect-theme);
}

html[data-theme="dark"] {
  @include mat.all-component-colors($too-perfect-theme-dark);
}